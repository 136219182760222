@import "@angular/cdk/overlay-prebuilt";
@import '@fortawesome/fontawesome-svg-core/styles.css';
@import "photoswipe/dist/photoswipe.css";

@import "variables";

@layer components {
    .btn {
        @apply bg-gray-50 uppercase border border-secondary-100 rounded-sm py-2 px-2 sm:px-4 md:px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-secondary-50;
    }
    .btn-primary {
        @apply bg-primary-700 border-primary-800 text-white hover:bg-primary-900 focus:outline-none focus:ring-2 focus:ring-primary-400 focus:ring-opacity-75 disabled:bg-gray-300 disabled:border-transparent;
    }
    .btn-secondary {
        @apply focus:outline-none border-secondary-200 bg-secondary-50 focus:ring-2 focus:ring-primary-400 focus:ring-opacity-75 disabled:bg-secondary-50 disabled:opacity-50;
    }
    .btn-neutral {
        @apply bg-gray-50 border-gray-300 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-secondary-400 focus:ring-opacity-75 disabled:bg-gray-100 disabled:opacity-50;
    }
    .btn-toggle {
        @apply border-secondary-600 text-sm font-medium hover:bg-secondary-100 focus:outline-none focus:ring-2 focus:ring-secondary-600 focus:ring-offset-2;
    }
    .btn-toggle-active {
        @apply bg-secondary-600 text-white
    }
    .btn-icon {
        @apply p-1 rounded text-gray-500 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-primary-400 disabled:opacity-50;
    }

    .input {
        @apply block w-full border-gray-300 rounded-sm shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm;

        &:disabled {
            @apply bg-gray-300 cursor-not-allowed;
        }
    }

    .text-link {
        @apply text-primary-600 hover:text-primary-800;
    }

    .text-primary {
        // same color as primary button
        @apply text-primary-700;
    }
}

mark {
    background-color: #cae2ff;
    border-radius: 3px;
    padding: 0 2px;
    margin: 0 -2px;
}
